import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../Literaryclub2023/components/Sidebar2024';
import Archive2023 from '../Literaryclub2023/components/Archive2023';
import LiterarySidebar from '../Literaryclub2023/components/LiterarySidebar';

const HindiCCA1and22024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_archive_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/association/LitreryClub/HindiCCA1and2/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/association/LitreryClub/HindiCCA1and2/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/association/LitreryClub/HindiCCA1and2/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/association/LitreryClub/HindiCCA1and2/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/association/LitreryClub/HindiCCA1and2/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/association/LitreryClub/HindiCCA1and2/6.webp`;

    const photos = [

        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },

    ];

    return (
        <Main>

            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                HINDI CCA 1 AND 2 
                                </Typography>
                                <br />
                                <Typography variant='h7' align='justify' >
                                    Class: 1 My Favourite Mythological Character
                                    <br />
                                    Class: 2 My Favourite Bird/Animal
                                    <br />

                                </Typography>

                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 1 & 2  Date: 22 November 2024 
                                </Typography>
                                {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                              ><br />
                                "Poetry comes alive to me through recitation!"
                                <br />
                            </Typography> */}
                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The Hindi CCA Activity for Classes 1&2 was held on Friday, 22 November 2024, which exhibited the creativity, artistic and speaking skills of the participants. It was an individual performance wherein each participant presented interesting facts, habits and importance of their chosen topics fluently in Hindi.
                                    <br />
                                    Class 1 students enacted their favourite mythological character and provided valuable insights about the lives of mythological characters. Students of class 2 enthusiastically dressed up and enacted as their favourite Bird/Animal, showcasing creativity in their costumes, props and dialogues.
                                    <br />
                                    The students combined their creative and oratory skills to put up a wonderful presentation.
                                    <br />
                                    The above activities proved to be an enriching learning experience. It enhanced their knowledge, creativity and communication skills.
                                    <br />
                                </Typography>
                                <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                              ><br />
                                Role Play is our brain's favourite way of learning 
                                <br />
                            </Typography>
                            </Box>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 500}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <LiterarySidebar />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default HindiCCA1and22024;